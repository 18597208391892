import Peer from "peerjs";
import React, { createContext, useCallback, useEffect, useRef, useState } from 'react';
import { Socket, connect } from "socket.io-client";

const socket = connect(process.env.REACT_APP_PEER_SERVER_HOST as string);

export enum USER_MODE {
    CENTRAL = 'CENTRAL',
    USER = 'USER'
}

interface WebRtcContextValue {
    setUserMode: (mode: USER_MODE) => void;
    updatePeerHub: (id: string) => Promise<void>;
    disconnectPeerHub: (id: string) => Promise<void>;
    saveEventLog: (eventLog: EventLog) => Promise<void>
    setPeerConnection: (peer: Peer) => void
    socket: Socket
    userMode?: USER_MODE;
    centralPeerHub?: PeerHub
    activityLogs: EventLog[]
}

export interface PeerHub {
    clientId: string;
    isOnline: boolean;
    date: Date
}

export enum EventType {
    INFO = 'INFO',
    ERROR = 'ERROR',
    WARNING = 'WARNING'
}

export interface EventLog {
    eventType: EventType
    eventOrigin: string;
    eventData: any;
    createdAt: Date;
}

export const WebRtcContext = createContext<WebRtcContextValue | null>(null);

export const WebRtcProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const userModeRef = useRef<USER_MODE | undefined>();
    const [peerConnection, setPeerConnection] = useState<Peer | undefined>()
    const [centralPeerHub, setCentralPeerHub] = useState<PeerHub | undefined>()
    const [activityLogs, setActivityLogs] = useState<EventLog[]>([])

    const setUserMode = (mode: USER_MODE) => {
        userModeRef.current = mode;
    }

    useEffect(() => {
        if (userModeRef.current === USER_MODE.USER) {
            const handleUpdatedCentralHub = (centralHub: PeerHub) => {
                setCentralPeerHub(centralHub);
            }

            socket.on('centralHub', handleUpdatedCentralHub)
            socket.on('centralHubUpdated', handleUpdatedCentralHub)
        }
    }, [userModeRef]);

    useEffect(() => {
        if (userModeRef.current === USER_MODE.CENTRAL) {
            const handlerLogsUpdated = (data: any) => {
                setActivityLogs(data as EventLog[]);
            }

            const handlerNewEventLog = (log: any) => {
                setActivityLogs((prev) => [
                    ...prev,
                    log as EventLog
                ]);
            }

            socket.on('eventLogsUpdated', handlerLogsUpdated)
            socket.on('newEventLog', handlerNewEventLog)

            return () => {
                socket.removeAllListeners()
            }
        }
    }, [userModeRef]);

    useEffect(() => {
        if (userModeRef.current === USER_MODE.CENTRAL && peerConnection?.id) {
            socket.emit('registerCentralPeer', {
                clientId: peerConnection.id,
                isOnline: true,
                date: new Date()
            })
        }
    }, [peerConnection])

    const updatePeerHub = async (id: string) => {
        try {
            socket.emit('registerCentralPeer', {
                clientId: id,
                isOnline: true,
                date: new Date()
            })
        } catch (error) {
            console.error("Error updating peerHub:", error);
        }
    };

    const disconnectPeerHub = async (id: string) => {
        try {
            socket.emit('unregisterCentralPeer', {
                clientId: id,
            })
        } catch (error) {
            console.error("Error disconnecting peerHub:", error);
        }
    };

    const saveEventLog = useCallback(async (eventLog: EventLog): Promise<void> => {
        try {
            if (!peerConnection) return
            socket.emit('eventLog', {
                ...eventLog,
                eventOrigin: peerConnection.id ?? socket.id ?? 'unknown-origin'
            })
        } catch (error) {
            console.error('Error saving event log:', error);
        }
    }, [peerConnection])

    return (
        <WebRtcContext.Provider value={{
            setUserMode,
            updatePeerHub,
            disconnectPeerHub,
            saveEventLog,
            setPeerConnection,
            socket,
            userMode: userModeRef.current,
            centralPeerHub,
            activityLogs
        }}>
            {children}
        </WebRtcContext.Provider>
    );
};