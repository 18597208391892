import { lazy } from 'react'
import { Navigate, RouterProvider, createBrowserRouter } from 'react-router-dom'
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { WebRtcProvider } from './states/contexts/WebRtcContext';

const Customer = lazy(() => import('./pages/customer'))
const CentralApp = lazy(() => import('./pages/central-app'))
const Screen = lazy(() => import('./pages/screen'))
const Landing = lazy(() => import('./pages/landing'))

const router = createBrowserRouter([
  {
    path: '/',
    element: <Customer />,
  },
  {
    path: '/central',
    element: <CentralApp />,
  },
  {
    path: '/screen',
    element: <Screen />,
  },
  {
    path: '/landing',
    element: <Landing />,
  },
  {
    path: '*',
    element: <Navigate to='/' replace />,
  },
])

function App() {
  return (
    <WebRtcProvider>
      <ToastContainer position="top-center" autoClose={process.env.REACT_APP_TIMER as any} limit={1} />
      <RouterProvider router={router} />
    </WebRtcProvider>
  )
}

export default App